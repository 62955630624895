export const SEGMENT_CONTENTS = "Segment Contents";
export const MEMBER_NUMBER = "Member Number";
export const CREATED_BY = "Created By";
export const CREATE_DATE = "Created Date";

export const segment_columns = [
    {
        title: "Member Number",
        key: "memberId",
        type: "string",
        visible: true,
    },
    {
        title: "Created By",
        key: "createdBy",
        type: "string",
        visible: true,
    },
    {
        title: "Created Date",
        key: "createdDate",
        type: "string",
        visible: true,
    }
]

export const tender_segment_columns = [
    {
        title: "Tender Code",
        key: "tenderCode",
        type: "string",
        visible: true,
    },
    {
        title: "Created By",
        key: "createdBy",
        type: "string",
        visible: true,
    },
    {
        title: "Created Date",
        key: "createdDate",
        type: "string",
        visible: true,
    }
]


export const email_segment_columns = [
    {
        title: "Email Address",
        key: "emailAddress",
        type: "string",
        visible: true,
    },
    {
        title: "Created By",
        key: "createdBy",
        type: "string",
        visible: true,
    },
    {
        title: "Created Date",
        key: "createdDate",
        type: "string",
        visible: true,
    }
]

export const popupConfig = {
	tootltip: true,
	theme: "",
	selectedDataKey: "",
	isDisabled: false
}
