export const CREATE_SEGMENT_MODAL_TITLE = "Start defining the segment";
export const SEGMENT_NAME_LABEL = "Segment Name";
export const CREATE_SEGMENT_TITLE = "What is the Segment Type";
export const SEGMENT_TYPES = [
	{
		title: "Member",
		key: "Static",
		type: "Member",
		header: "Upload text file with list of members.",
		description:
			"Static list of members, can be appended or overwritten later.",
		status: "&nbsp;",
		disabled: false,
		rights: ["popup", "segmentation", "createsegment", "static"]
	},
	{
		title: "Email (non-member)",
		key: "Email (non-member)",
		type: "Email",
		header: "Upload CSV file with list of email address and attributes of non-members.",
		description: "Static list of customer email addresses.",
		status: "&nbsp;",
		disabled: false,
		rights: ["popup", "segmentation", "createsegment", "email"]
	},
	{
		title: "Tender",
		key: "Tender",
		type: "Tender",
		header: "Upload text file with list of tenders.",
		description: "Static list of tenders can be appended or overwritten.",
		status: "&nbsp;",
		disabled: false,
		rights: ["popup", "segmentation", "createsegment", "static"]
	},
];
export const COMING_SOON_STATUS = "coming soon";
export const CONTINUE_TO_SETUP = "Continue to setup";
