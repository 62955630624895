export const SEGMENT_DETAILS_TITLE = "Segment details";
export const SEGMENT_NAME_LABEL = "Segment Name";
export const SEGMENT_TYPE_LABEL = "Segment Type";
export const SEGMENT_SUBTYPE_LABEL = "Segment SubType";
export const STORE_TYPE = "storeType";

export const SEGMENT_DESCRIPTION_LABEL = "Description";
export const SEGMENT_COUNT_LABEL = "Count";
export const SEGMENT_LINKED_OFFERS_LABEL = "Linked Offers";
export const SEGMENT_LAST_UPDATEDBY_LABEL = "Modified by";
export const SEGMENT_LAST_UPDATEDTS_LABEL = "Modified Date";
