/* eslint-disable jsx-a11y/anchor-is-valid */
import FilesDragAndDrop from "../../utilsComponent/FilesDragAndDropUtil/FilesDragAndDrop";
import "./UploadList.style.scss";
import { AiFillPicture, AiFillEye, AiOutlinePaperClip } from "react-icons/ai";
import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import xlsx_template from "/public/segmentTemplates/Email_group_template.xlsx";
// import csv_template from "/public/segmentTemplates/Email_Group_Template.CSV";
import xlsx_template from "../../../tellurideExtAssets/template/Email_group_template.xlsx";
import csv_template from "../../../tellurideExtAssets/template/Email_Group_Template.CSV";


import {
	deleteImageContent,
	loadImageContent,
	setUploadImage,
	upadteLinkedOfferData,
	uploadImageContent,
	uploadFile,
	setUploadFileFlag,
	updateSegmentDetails,
} from "../../../tellurideExtRedux";
import { images } from "../../imagesComponent/Images";
import { useRef } from "react";
import {
	miniloading,
	miniloadingWithoutMessage,
} from "../../LoaderComponents/loadingDesignConstant";
import { AnimatePresence } from "framer-motion/dist/framer-motion";
import { skey } from "../../utilsComponent/CommonUtil";
import {
	ENABLED,
	FILE_UPLOAD_SUCCESSULL_TEXT,
	SEGMENT,
	headers,
	uploadFileHeader,
} from "../../constantComponent/Constants";
import _ from "lodash";
import {
	APPEND,
	DOWNLOAD_TEMPLATE_HEADLINE,
	DOWNLOAD_XSLX_TEMPLATE_HEADLINE,
	DOWNLOAD_CSV_TEMPLATE_HEADLINE,
	FILE_SUPPORT_TEXT_SEGMENT,
	OVERRIDE,
	EMAIL_SEGMENT_UPLOAD_ADDITIONAL_TEXT,
	SEGMENT_UPLOAD_TEXT,
	UPLOAD_FILE_SEGMENT_HEADLINE,
	popupConfig,
	upload_list_columns,
} from "./UploadListConstants";
import { FiDownload } from "react-icons/fi";
import SmallGenericTable from "../../utilsComponent/SmallGenericTable/SmallGenericTable";
import isEqual from "react-fast-compare";

const EmailSegmentUploadList = (props) => {
	const segmentData = useSelector((state) => state.segmentDetailsData);
	const segmentDetails = segmentData.segmentDetailsResponse;
	const [updateSegmentData, setUpdateSegmentData] = useState(
		JSON.parse(JSON.stringify(segmentData.updateSegmentDetailsResponse))
	);
	const offerData = useSelector((state) => state.offerDetailsData);
	const [uploadedFile, setUploadedFile] = useState("");
	const dispatch = useDispatch();
	const communicationSection = useRef();
	const [isEnabled, setDisable] = useState(
		segmentData.segmentMode === ENABLED ? true : false || !props.disabled
	);
	const [btnClicked, setBtnClicked] = useState(false);
	const [fileLoaded, setFileLoaded] = useState(false);
	const [showInfo, setShowInfo] = useState(false);
	const securityData = useSelector((state) => state.securityData);
	const [action, setAction] = useState("Append");
	//theme
	const toasterData = useSelector((state) => state.toasterData);
	const [theme, setTheme] = useState(toasterData.isDarkTheme);

	/*const downloadFile = async (name, path) => {
		try {
			const response = await fetch(path);
			const blob = await response.blob();

			const url = window.URL.createObjectURL(blob);
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", name);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} catch (error) {
			console.error("Error downloading the file", error);
		}
	};*/
	useEffect(() => {
		setTheme(toasterData.isDarkTheme);
	}, [toasterData]);
	// theme ends

	useEffect(() => {
		segmentData.uploadImageContentResponse = "";
		segmentData.loadImageContentResponse = "";
		segmentData.deleteImageResponse = "";
		segmentData.imageUploadJson = "";
	}, []);

	useEffect(() => {
		setDisable(
			segmentData.segmentMode === ENABLED ? true : false || !props.disabled
		);
	}, [segmentData.segmentMode, props.disabled]);

	useEffect(() => {
		setUpdateSegmentData((prev) => {
			return {
				...prev,
				action: action,
			};
		});
	}, [action]);

	useEffect(() => {
		dispatch(updateSegmentDetails(updateSegmentData));
	}, [updateSegmentData]);

	useEffect(() => {
		if (!isEqual(segmentData.updateSegmentDetailsResponse, updateSegmentData)) {
			setUpdateSegmentData(
				JSON.parse(JSON.stringify(segmentData.updateSegmentDetailsResponse))
			);
		}
	}, [segmentData.updateSegmentDetailsResponse]);

	const deleteFile = () => {
		setUploadedFile(null);
		props.setUploadedFile(null);
		dispatch(setUploadFileFlag(false));
		setFileLoaded(false);
	};

	const onUpload = (files) => {
		setUpdateSegmentData((prev) => {
			return {
				...prev,
				action: action,
			};
		});
		setUploadedFile(files);
		dispatch(setUploadFileFlag(true));
		const file = files[0];
		props.setUploadedFile(file);
		setFileLoaded(true);
	};

	return (
		<div className="segment-section">
			<div className="upload_top_margin"> </div>
			<div className="upload-title setup-page-section-title">
				{UPLOAD_FILE_SEGMENT_HEADLINE}
				<div>
					<img
						className="info-img"
						src={images.infoImage}
						onMouseEnter={() => {
							setShowInfo(true);
						}}
						onMouseLeave={() => {
							setShowInfo(false);
						}}
					></img>
					{showInfo && (
						<>
							<div
								className="info-msg-point"
								onMouseEnter={() => {
									setShowInfo(true);
								}}
								onMouseLeave={() => {
									setShowInfo(false);
								}}
							>
								<div className="triangle"></div>
								Large file uploads may take from a few minutes to a few hours to
								complete. Check back after a while to see the upload status and
								any errors during upload in this page.
							</div>
						</>
					)}
				</div>
			</div>
			<div className="segment-upload-main-div">
				<div className="segment-upload-section-inner">
					<div className="mb-16 communication-content-div">
						<div className="upload_right_align">
							<div>
								{" "}
								<a
									href={csv_template}
									type="text/csv"	
									download="Email_Group_Template.CSV"
									/*onClick={() =>
										downloadFile(
											"Email_Group_Template.CSV",
											process.env.PUBLIC_URL +
												"/segmentTemplates/Email_Group_Template.CSV"
										)
									}*/
									target="_self"
									className="preview-link"
								>
									{DOWNLOAD_CSV_TEMPLATE_HEADLINE}
								</a>
								<br></br>
								<a
									href={xlsx_template}
									type="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
									download="Email_group_template.xlsx"
									/*onClick={() =>
										downloadFile(
											"Email_group_template.xlsx",
											process.env.PUBLIC_URL +
												"/segmentTemplates/Email_group_template.xlsx"
										)
									}*/
									target="_self"
									className="preview-link"
								>
									{DOWNLOAD_XSLX_TEMPLATE_HEADLINE}
								</a>
							</div>
						</div>
					</div>

					<div>
						<div className="file-upload-section">
							{!segmentData.isFileUploaded && (
								<FilesDragAndDrop
									onUpload={onUpload}
									isSetDisable={!isEnabled}
									uploadText={SEGMENT_UPLOAD_TEXT}
									additionalText={EMAIL_SEGMENT_UPLOAD_ADDITIONAL_TEXT}
									acceptFileTypes={".csv,.xlsx"}
									sourceComponent={SEGMENT}
									maxFileSize={""}
								/>
							)}
						</div>
						{fileLoaded && (
							<div className="uploaded-images">
								<label className="file_upload_success_msg">
									{FILE_UPLOAD_SUCCESSULL_TEXT}
								</label>
								<>
									<div className="attachment_div">
										<div className="image-thumbnail">
											<AiOutlinePaperClip />
										</div>
										<div className="flex">
											{
												<label className="truncate">
													{props.uploadedFile.name}
												</label>
											}

											<p className="flex center">
												<img
													onClick={() => {
														if (isEnabled) {
															deleteFile();
														}
													}}
													className={
														!isEnabled
															? "image-delete-icon disabled"
															: "image-delete-icon"
													}
													src={images.trashImage}
												/>
											</p>
										</div>
									</div>
								</>
							</div>
						)}
						<div className="file-upload-section">
							<input type="hidden" id="fileUploadOption" value="Append" />
							<div className="selection-buttons">
								{isEnabled && (
									<button
										className={
											action === "Append"
												? `append-button focus btn btn-primary`
												: `append-button btn btn-secondary`
										}
										onClick={(e) => {
											// document.getElementById("fileUploadOption").value="Append";
											setAction(APPEND);
										}}
									>
										{APPEND}
									</button>
								)}
								{isEnabled && (
									<button
										className={
											action === "Override"
												? `override-button focus btn btn-primary`
												: `override-button btn btn-secondary`
										}
										onClick={(e) => {
											setAction(OVERRIDE);
										}}
									>
										{OVERRIDE}
									</button>
								)}
							</div>
							{isEnabled && (
								<div className="selection-btn-info">
									<img className="info-img" src={images.infoImage}></img>
									<div>
										<p>
											Choose "Append" if the new file's contents need to be
											added to existing segment.
										</p>

										<p>
											Choose "Override", if the existing contents should be
											replaced by new file contents.
										</p>
									</div>
								</div>
							)}
						</div>
						{/* change the status here */}
						{segmentDetails?.fileUploadDetailsList?.length > 0 && (
							<div className="upload-status-table">
								<div className="status-header setup-page-section-title">
									Upload Status
								</div>
								<SmallGenericTable
									Columns={upload_list_columns}
									tableData={segmentDetails?.fileUploadDetailsList}
									isDataLoading={fileLoaded}
									isRowSelectable={false}
									isRowMultiSelectable={false}
									config={{
										...popupConfig,
										theme: theme,
										selectedDataKey: "id",
										border: false,
										isDisabled: true,
									}}
									isShowPagination={false}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default EmailSegmentUploadList;
