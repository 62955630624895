import { React, useMemo, useState } from "react";
import "./CreateContentComponent.style.scss";
import { FaRegTimesCircle } from "react-icons/fa";
import { images } from "../../imagesComponent/Images";
import { useNavigate } from "react-router-dom";
// import { DETAILS, OFFER } from "../../constantComponent/PathConstants";``
import {
	COMING_SOON,
	ESLO,
	headers,
	OFRI,
	SUCCESS,
} from "../../constantComponent/Constants";
import { useDispatch, useSelector } from "react-redux";
import {
	createUpdateContentOffer,
	createUpdateOffer,
} from "../../../tellurideExtRedux/offerDetails/OfferDetailsAction";
import { useEffect } from "react";
import {
	loading,
	miniloading,
} from "../../LoaderComponents/loadingDesignConstant";
import {
	CONTENT_NAME_LABEL,
	CONTENT_OFFER_TITLE,
	CONTENT_TYPES,
	CREATE_CONTENT_MODAL_TITLE,
	CREATE_OFFER_MODAL_TITLE,
	CREATE_OFFER_POINTS_DESCRIPTION,
	CREATE_OFFER_POINTS_HEADER,
	CREATE_OFFER_POINTS_TITLE,
	CREATE_OFFER_PRICE_DESCRIPTION,
	CREATE_OFFER_PRICE_HEADER,
	CREATE_OFFER_PRICE_TITLE,
	CREATE_OFFER_TITLE,
	OFFER_NAME_LABEL,
	OFFER_TYPES,
	SCREEN_NAME_CONTENT,
} from "./CreateContentConstant";
import { useAuth0 } from "@auth0/auth0-react";
import { motion } from "framer-motion/dist/framer-motion";
import {
	checkRightsIsEditable,
	checkRightsIsVisible,
	getAllRights,
	getRightsFromSession,
	getRightsKey,
	skey,
	trimExtraSpaces,
} from "../../utilsComponent/CommonUtil";
import { COMING_SOON_STATUS } from "../../segmentSetupComponent/createSegmentComponent/CreateSegmentConstants";
import {
	CAMPAIGN,
	CONTENT,
	DETAILS,
	CREATE,
} from "../../constantComponent/PathConstants";
import { getOfferTypeList } from "../../../tellurideExtRedux";
import { getIcon } from "../../utilsComponent/SidebarIcons";
import { emptyData } from "../../constantComponent/DesignConstant";

const CreateContentComponent = ({ setSelectOffer, setShowContentPopup }) => {
	const dispatch = useDispatch();
	const contentData = useSelector((state) => state.offerDetailsData);
	const securityData = useSelector((state) => state.securityData);
	const [selectedEarn, setSelectedEarn] = useState(true);
	const [selectedDiscount, setSelectedDiscount] = useState(false);
	const [contentDetails, setContentDetails] = useState({
		key: "1",
	});
	const [contentName, setContentName] = useState("");
	let navigate = useNavigate();
	const { user } = useAuth0();
	const handleChangeContentName = (e) => {
		setContentName(e.target.value);
	};
	const metaData = useSelector((state) => state.metaData);
	const offerTypesLoading = metaData?.offerTypeListLoading;
	const offerTypesLoadedError = metaData?.offerTypeListError;
	const offerTypesResponse = useMemo(
		() => metaData?.offerTypeListResponse || [],
		[metaData]
	);
	const createHandler = () => {
		headers.sessionId = skey();
		headers.requestAccess = getRightsFromSession();
		headers.organization = contentData?.authOrganizationName;
		const requestHeaders = {
			headers,
		};
		const requestBody = {
			requestorID: OFRI,
			messageVersion: process.env.REACT_APP_MESSAGE_VERSION_05,
			offerData: {
				offerType: contentDetails?.offerType,
				// offerSubType: contentDetails?.offerSubType,
				offerPointsDollarName: trimExtraSpaces(contentName),
				modifiedBy: user?.email,
				// offerCategory: contentDetails?.category,
				// offerSubCategory: contentDetails?.subCategory,
				businessInitiative: contentData?.authOrganizationName
			},
			actions: {
				action: [
					{
						actionName: "My Action",
						actionType: "MESSAGE",
						actionJson: {
							category: contentDetails?.category,
							subCategory: contentDetails?.subCategory,
						},
					},
				],
			},
		};
		dispatch(
			createUpdateContentOffer(
				requestHeaders,
				requestBody,
				false,
				contentData?.organizationName,
				navigate,
				null,
				window.location.pathname.includes(DETAILS) ? true : false
			)
		);
	};
	useEffect(() => {
		if (
			contentData?.createUpdateContentOfferLoading === false &&
			contentData?.createUpdateContentOfferResponse?.linkedOffers &&
			contentData?.createUpdateContentOfferResponse?.linkedOffers[0]?.status ===
				SUCCESS &&
			contentData?.createUpdateContentOfferResponse?.linkedOffers[0]
				?.offerNumber
		) {
			navigate({
				pathname:
					"/" +
					window.location.pathname.split("/")[1] +
					CAMPAIGN +
					CONTENT +
					DETAILS,
				search: `?offerid=${contentData?.createUpdateContentOfferResponse?.linkedOffers[0]?.offerNumber}`,
			});
			setSelectOffer(true);
			setShowContentPopup(false);
		}
	}, [dispatch, contentData]);
	function handleContentSelect(content) {
		setContentDetails(content);
	}
	useEffect(() => {
		headers.sessionId = skey();
		headers.requestAccess = getRightsFromSession();
		headers.organization = contentData?.authOrganizationName;
		const requestHeaders = {
			headers,
		};
		dispatch(getOfferTypeList(requestHeaders, { id: SCREEN_NAME_CONTENT }));
	}, []);
	useEffect(() => {
		if (offerTypesLoading === false && offerTypesResponse) {
			offerTypesResponse.map((content) => {
				if (content.key === "1") {
					setContentDetails(content);
				}
			});
		}
	}, [offerTypesLoading, offerTypesResponse]);

	return (
		<section className="content-modal-backdrop">
			<motion.div
				className="inner-modal"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
			>
				<div className="header">
					<h1>{CREATE_CONTENT_MODAL_TITLE}</h1>
					<FaRegTimesCircle
						onClick={() => {
							setShowContentPopup(false);
						}}
						size={25}
						className="content-modal-close-icon"
					/>
				</div>
				<fieldset>
					<legend>{CONTENT_NAME_LABEL}</legend>
					<input
						type="text"
						value={contentName}
						onChange={handleChangeContentName}
						disabled={
							contentData.createUpdateContentOfferLoading === true
								? true
								: false
						}
						autoFocus
						maxLength="50"
					/>
				</fieldset>

				<p className="create-content-title">{CONTENT_OFFER_TITLE}</p>
				{offerTypesLoading !== false ? (
					loading()
				) : offerTypesLoadedError === true ? (
					emptyData("Something went wrong ! please try again later.")
				) : (
					<div className="content-type-section">
						{offerTypesResponse
							?.sort((a, b) => parseInt(a?.key) - parseInt(b?.key))
							.map((content) => {
								return (
									<>
										{checkRightsIsVisible(getRightsKey(content?.rightKey)) && (
											<div
												className={`content-type pt-20 ${
													contentDetails.key == content.key && "selected"
												} ${content.disabled === "true" && "disabled"}`}
												onClick={() => {
													if (
														content.disabled !== "true" ||
														!checkRightsIsEditable(
															getRightsKey(content?.rightKey)
														)
													) {
														handleContentSelect(content);
													}
												}}
												key={content.key}
											>
												<input
													type="radio"
													className={`contenttype-radio-input ${
														contentDetails.key == content.key && "selected"
													} ${
														content.disabled === "true" ||
														!checkRightsIsEditable(
															getRightsKey(content?.rightKey)
														)
															? "disabled"
															: ""
													}`}
													checked={
														content.key === contentDetails.key ? true : false
													}
													disabled={
														content.disabled === "true" ||
														!checkRightsIsEditable(
															getRightsKey(content?.rightKey)
														)
															? true
															: false
													}
													name={content.title}
												/>
												<span className="content-icon">
													{getIcon[content?.icon]()}
												</span>
												<p className="content-title">{content.title}</p>
												<p className="content-header">{content.header}</p>
												<p className="content-body">{content.description}</p>
												{content.status === COMING_SOON_STATUS && (
													<p className="content-status">Coming soon</p>
												)}
											</div>
										)}
									</>
								);
							})}
					</div>
				)}
				<footer className="content-modal-footer">
					<button
						className="btn btn-secondary"
						onClick={() => {
							setShowContentPopup(false);
						}}
						disabled={
							contentData.createUpdateContentOfferLoading === true
								? true
								: false
						}
					>
						Cancel
					</button>
					{checkRightsIsVisible(
						getRightsKey([
							"button",
							"popup",
							"campaign",
							"content",
							"createcontent",
							"continuetosetup",
						])
					) ? (
						<button
							className="btn btn-primary continue-setup-btn"
							disabled={
								contentName.trim() === "" ||
								contentData.createUpdateContentOfferLoading ||
								offerTypesLoadedError ||
								!checkRightsIsEditable(
									getRightsKey([
										"button",
										"popup",
										"campaign",
										"content",
										"createcontent",
										"continuetosetup",
									])
								)
							}
							onClick={() => {
								if (
									contentName.trim() !== "" ||
									!contentData.createUpdateContentOfferLoading ||
									!offerTypesLoadedError ||
									!checkRightsIsEditable(
										getRightsKey([
											"button",
											"popup",
											"campaign",
											"content",
											"createcontent",
											"continuetosetup",
										])
									)
								) {
									createHandler();
								}
							}}
						>
							{contentData.createUpdateContentOfferLoading
								? miniloading("Processing...")
								: "Continue to setup"}
						</button>
					) : null}
				</footer>
			</motion.div>
		</section>
	);
};

export default CreateContentComponent;
