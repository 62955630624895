import { React, useState } from "react";
import "./CreateSegmentComponent.style.scss";
import { FaRegTimesCircle } from "react-icons/fa";
import { images } from "../../imagesComponent/Images";
import { useNavigate } from "react-router-dom";
import { DETAILS, SEGMENTS } from "../../constantComponent/PathConstants";
import {
	COMING_SOON,
	ESLO,
	headers,
	uploadFileHeader,
	OFRI,
	ENABLED,
	SUCCESS,
	RequestAccess,
	CONTINUE_TO_SETUP,
	PROCESSING_LOADER,
} from "../../constantComponent/Constants";
import { useDispatch, useSelector } from "react-redux";
import { createUpdateSegment } from "../../../tellurideExtRedux/segmentation/SegmentDetailsAction";
import { useEffect } from "react";
import { miniloading } from "../../LoaderComponents/loadingDesignConstant";
import { useAuth0 } from "@auth0/auth0-react";
import { motion } from "framer-motion/dist/framer-motion";
import {
	checkRightsIsEditable,
	checkRightsIsVisible,
	getAllRights,
	getRightsFromSession,
	getRightsKey,
	skey,
	trimExtraSpaces,
} from "../../utilsComponent/CommonUtil";
import {
	COMING_SOON_STATUS,
	CREATE_SEGMENT_MODAL_TITLE,
	CREATE_SEGMENT_TITLE,
	SEGMENT_NAME_LABEL,
	SEGMENT_TYPES,
} from "./CreateSegmentConstants";
import { SEGMENT_CREATE } from "../../errorsComponent/ErrorConstant";

const CreateSegmentComponent = ({ setSelectOffer, setShowOfferPopup }) => {
	const dispatch = useDispatch();
	const offerData = useSelector((state) => state.offerDetailsData);
	const segmentData = useSelector((state) => state.segmentDetailsData);
	const securityData = useSelector((state) => state.securityData);
	const [segmentType, setSegmentType] = useState("Static");
	const [segmentName, setSegmentName] = useState("");
	let navigate = useNavigate();
	const { user } = useAuth0();
	const handleChangeSegmentName = (e) => {
		setSegmentName(e.target.value);
	};
	const [selectedSegmentType, setSelectedSegmentType] = useState("Member");
	const createSegmentHandler = () => {
		uploadFileHeader.sessionId = skey();
		uploadFileHeader.organization = offerData?.authOrganizationName;
		uploadFileHeader.requestAccess = getRightsFromSession();
		const headers = uploadFileHeader;
		headers.sessionId = skey();
		headers.requestAccess = getRightsFromSession();
		headers.organization = offerData?.authOrganizationName;
		const requestHeaders = {
			headers,
		};
		let requestBody = new FormData();
		requestBody.append("file", "");
		requestBody.append("requestorID", OFRI);
		requestBody.append(
			"messageVersion",
			process.env.REACT_APP_MESSAGE_VERSION_01
		);
		requestBody.append("name", trimExtraSpaces(segmentName));
		requestBody.append("description", "");
		if(selectedSegmentType =='Tender'){
			requestBody.append("subType", "")
		}
		requestBody.append("type", selectedSegmentType);
		requestBody.append("action", "");
		requestBody.append("userId", user ? user.email : "");
		dispatch(
			createUpdateSegment(
				requestHeaders,
				requestBody,
				true,
				ENABLED,
				SEGMENT_CREATE,
				null,
				window.location.pathname.includes(DETAILS) ? true : false
			)
		);
	};

	useEffect(() => {
		if (
			segmentData?.createUpdateSegmentLoading === false &&
			segmentData?.createUpdateSegmentResponseStatus === "Success"
		) {
			navigate({
				pathname:
					"/" + window.location.pathname.split("/")[1] + SEGMENTS + DETAILS,
				search: `?groupId=${segmentData?.createUpdateSegmentResponse?.id}`,
			});
			if (segmentData.isNewSegmentFlow === "") {
				segmentData.isNewSegmentFlow = true;
			}
			setShowOfferPopup(false);
		}
	}, [dispatch, segmentData]);
	function handleOfferSelect(offer) {
		setSegmentType(offer.key);
	}
	return (
		<section className="create-segment-modal-backdrop">
			<motion.div
				className="inner-modal"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
			>
				<div className="header">
					<h1>{CREATE_SEGMENT_MODAL_TITLE}</h1>
					<FaRegTimesCircle
						onClick={() => {
							setShowOfferPopup(false);
						}}
						size={25}
						className="create-segment-modal-close-icon"
					/>
				</div>
				<fieldset>
					<legend>{SEGMENT_NAME_LABEL}</legend>
					<input
						type="text"
						value={segmentName}
						onChange={handleChangeSegmentName}
						disabled={offerData.createUpdateOfferLoading}
						autoFocus
						maxLength="50"
					/>
				</fieldset>

				<p className="create-segment-title">{CREATE_SEGMENT_TITLE}</p>
				<div className="segment-type-section">
					{SEGMENT_TYPES.map((segment, index) => {
						return (
							<>
								{checkRightsIsVisible(getRightsKey(segment?.rights)) && (
									<div
										key={index}
										className={`segment-type ${
											segmentType === segment.key && "selected"
										} ${
											(segment.disabled === true && "disabled") ||
											(!checkRightsIsEditable(getRightsKey(segment.rights)) &&
												"disabled")
										}`}
										onClick={() => {
											setSelectedSegmentType(segment.type);
											if (
												!segment.disabled ||
												!checkRightsIsEditable(getRightsKey(segment.rights))
											) {
												handleOfferSelect(segment);
											}
										}}
									>
										<input
											type="radio"
											className={`segmenttype-radio-input ${
												segment.key === segmentType && "selected"
											} ${
												(segment.disabled === true && "disabled") ||
												(!checkRightsIsEditable(getRightsKey(segment.rights)) &&
													"disabled")
											}`}
											checked={segment.key === segmentType ? true : false}
											disabled={
												segment.disabled === true ||
												!checkRightsIsEditable(getRightsKey(segment.rights))
													? true
													: false
											}
											value={segment.type}
											onChange={(e) =>
												setSelectedSegmentType(e.target.value)
											  }
											  
										/>
										<p className="segment-title">{segment.title}</p>
										<p className="segment-header">{segment.header}</p>
										<p className="segment-body">{segment.description}</p>
										{segment.status === COMING_SOON_STATUS && (
											<p className="segment-footer">{COMING_SOON}</p>
										)}
									</div>
								)}
							</>
						);
					})}
				</div>
				<footer className="create-segment-modal-footer">
					<button
						className="btn btn-secondary"
						onClick={() => {
							setShowOfferPopup(false);
						}}
						disabled={segmentData.createUpdateSegmentLoading}
					>
						Cancel
					</button>
					{checkRightsIsVisible(
						getRightsKey([
							"popup",
							"segmentation",
							"createsegment",
							"continuetosetupbutton",
						])
					) ? (
						<button
							className="btn btn-primary continue-setup-btn"
							disabled={
								segmentName.trim() === "" ||
								segmentData.createUpdateSegmentLoading ||
								!checkRightsIsEditable(
									getRightsKey([
										"popup",
										"segmentation",
										"createsegment",
										"continuetosetupbutton",
									])
								)
							}
							onClick={() => {
								createSegmentHandler();
							}}
						>
							{segmentData.createUpdateSegmentLoading
								? miniloading(PROCESSING_LOADER)
								: CONTINUE_TO_SETUP}
						</button>
					) : null}
				</footer>
			</motion.div>
		</section>
	);
};

export default CreateSegmentComponent;
