import axios from "axios";
import { setErrorToaster, setSuccessToaster } from "..";
import {
	GET_SEGMENT_LIST_REQUEST,
	GET_SEGMENT_LIST_SUCCESS,
	GET_SEGMENT_LIST_FAILURE,
	GET_SEGMENT_LIST_SUCCESS_FAILURE,
	GET_SEGMENT_DETAILS_REQUEST,
	GET_SEGMENT_DETAILS_SUCCESS,
	GET_SEGMENT_DETAILS_FAILURE,
	GET_SEGMENT_DETAILS_SUCCESS_FAILURE,
	SEARCH_SEGMENT_CONTENT_REQUEST,
	SEARCH_SEGMENT_CONTENT_SUCCESS,
	SEARCH_SEGMENT_CONTENT_FAILURE,
	SEARCH_SEGMENT_CONTENT_SUCCESS_FAILURE,
	CREATE_UPDATE_SEGMENT_REQUEST,
	CREATE_UPDATE_SEGMENT_SUCCESS,
	CREATE_UPDATE_SEGMENT_SUCCESS_FAILURE,
	CREATE_UPDATE_SEGMENT_FAILURE,
	UPLOAD_FILE_REQUEST,
	UPLOAD_FILE_SUCCESS,
	UPLOAD_FILE_FAILURE,
	UPLOAD_FILE_SUCCESS_FAILURE,
	SET_BLANK_SESSION_ID_ERROR,
	SET_SESSION_TIMEOUT_POPUP,
	SET_AUTH_ORGANZTATION_NAME,
	SET_SEGMENT_DETAILS,
	SET_UPLOAD_FILE,
	SET_SEGMENT_MODE,
	SET_SEGMENT_EDIT_STATUS,
	RESET_CREATE_UPDATE_SEGMENT,
} from "./SegmentDetailsType";
import {
	SEGMENTS,
	VIEW_SEGMENT,
	DETAILS,
} from "../../tellurideExtComponents/constantComponent/PathConstants";
import {
	ERROR_MESSAGE,
	setBlankSession,
	setSessionExpire,
	setToaster,
} from "../reduxUtil";
import {
	SEGMENT_SAVE,
	SEGMENT_UPDATE,
	SEGMENT_FILE_UPLOAD_SUCCESS,
	SEGMENT_FILE_UPLOAD_ERROR,
	SEGMENT_FILE_DELETE_SUCCESS,
} from "../../tellurideExtComponents/errorsComponent/ErrorConstant";
import { headers } from "../../tellurideExtComponents/constantComponent/Constants";
export const getSegmentsList = (
	requestHeaders,
	requestBody,
	apiurl,
	controller
) => {
	return async (dispatch) => {
		dispatch(getSegmentListRequest());
		try {
			const resp = await axios.post(apiurl, requestBody, {
				...requestHeaders,
				signal: controller.signal,
			});
			if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
				dispatch(getSegmentListSuccessFailure(resp.data));
				dispatch(setErrorToaster(resp.data.errors[0].message));
				setSessionExpire(resp, setSessionExpirePopUp, dispatch);
			} else {
				dispatch(getSegmentListSuccess(resp.data));
			}
		} catch (err) {
			dispatch(getSegmentListFailure(err.message));
			setToaster(err, ERROR_MESSAGE, dispatch);
			setSessionExpire(err, setSessionExpirePopUp, dispatch);
			setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
		}
	};
};

export const getSegmentDetails = (requestHeaders, requestBody, navigate) => {
	return (dispatch) => {
		dispatch(getSegmentDetailsRequest());
		axios
			.post(
				process.env.REACT_APP_URL_DOMAIN +
					process.env.REACT_APP_GET_SEGMENT_DETAILS,
				requestBody,
				requestHeaders
			)
			.then((resp) => {
				if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
					navigate(
						"/" +
							window.location.pathname.split("/")[1] +
							SEGMENTS +
							VIEW_SEGMENT
					);
					dispatch(getSegmentDetailsSuccessFailure(resp.data));
					dispatch(setErrorToaster(resp.data.errors[0].message));
					setSessionExpire(resp, setSessionExpirePopUp, dispatch);
					dispatch(setUploadFileFlag(false));
				} else {
					dispatch(getSegmentDetailsSuccess(resp.data));
					dispatch(setUploadFileFlag(false));
				}
			})
			.catch((err) => {
				navigate(
					"/" + window.location.pathname.split("/")[1] + SEGMENTS + VIEW_SEGMENT
				);
				dispatch(getSegmentDetailsFailure(err.message));
				setToaster(err, ERROR_MESSAGE, dispatch);
				setSessionExpire(err, setSessionExpirePopUp, dispatch);
				setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
				dispatch(setUploadFileFlag(false));
			});
	};
};

export const searchSegmentContent = (requestHeaders, requestBody) => {
	return (dispatch) => {
		dispatch(searchSegmentContentRequest());
		const resp = axios
			.post(
				process.env.REACT_APP_URL_DOMAIN +
					process.env.REACT_APP_SEARCH_SEGMENT_CONTENT,
				requestBody,
				requestHeaders
			)
			.then((resp) => {
				if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
					//navigate(SEGMENTS + VIEW_SEGMENT);
					dispatch(searchSegmentContentSuccessFailure(resp.data));
					dispatch(setErrorToaster(resp.data.errors[0].message));
					setSessionExpire(resp, setSessionExpirePopUp, dispatch);
				} else {
					dispatch(searchSegmentContentSuccess(resp.data));
				}
			})
			.catch((err) => {
				//navigate(SEGMENTS + VIEW_SEGMENT);
				dispatch(searchSegmentContentFailure(err.message));
				setToaster(err, ERROR_MESSAGE, dispatch);
				setSessionExpire(err, setSessionExpirePopUp, dispatch);
				setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
			});
	};
};

export const searchEmailSegmentContent = (requestHeaders, requestBody) => {
	return (dispatch) => {
		dispatch(searchSegmentContentRequest());
		const resp = axios
			.post(
				process.env.REACT_APP_URL_DOMAIN +
					process.env.REACT_APP_SEARCH_EMAIL_SEGMENT_CONTENT,
				requestBody,
				requestHeaders
			)
			.then((resp) => {
				if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
					dispatch(searchSegmentContentSuccessFailure(resp.data));
					dispatch(setErrorToaster(resp.data.errors[0].message));
					setSessionExpire(resp, setSessionExpirePopUp, dispatch);
				} else {
					dispatch(searchSegmentContentSuccess(resp.data));
				}
			})
			.catch((err) => {
				dispatch(searchSegmentContentFailure(err.message));
				setToaster(err, ERROR_MESSAGE, dispatch);
				setSessionExpire(err, setSessionExpirePopUp, dispatch);
				setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
			});
	};
};

export const searchTenderSegmentContent = (requestHeaders, requestBody) => {
	return (dispatch) => {
		dispatch(searchSegmentContentRequest());
		const resp = axios
			.post(
				process.env.REACT_APP_URL_DOMAIN +
					process.env.REACT_APP_SEARCH_TENDER_SEGMENT_CONTENT,
				requestBody,
				requestHeaders
			)
			.then((resp) => {
				if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
					dispatch(searchSegmentContentSuccessFailure(resp.data));
					dispatch(setErrorToaster(resp.data.errors[0].message));
					setSessionExpire(resp, setSessionExpirePopUp, dispatch);
				} else {
					dispatch(searchSegmentContentSuccess(resp.data));
				}
			})
			.catch((err) => {
				dispatch(searchSegmentContentFailure(err.message));
				setToaster(err, ERROR_MESSAGE, dispatch);
				setSessionExpire(err, setSessionExpirePopUp, dispatch);
				setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
			});
	};
};

export const createUpdateSegment = (
	requestHeaders,
	requestBody,
	isShowToaster,
	mode,
	msg,
	navigate,
	getDetailsCallRequired
) => {
	return async (dispatch) => {
		dispatch(createUpdateSegmentRequest());
		try {
			const resp = await axios.post(
				process.env.REACT_APP_URL_DOMAIN +
					process.env.REACT_APP_CREATE_UPDATE_SEGMENT,
				requestBody,
				requestHeaders
			);
			if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
				dispatch(createUpdateSegmentSuccessFailure(resp.data));
				dispatch(setErrorToaster(resp.data.errors[0].message));
				setSessionExpire(resp, setSessionExpirePopUp, dispatch);
				dispatch(setUploadFileFlag(false));
			} else if (resp.data.statusCode === "01") {
				dispatch(createUpdateSegmentSuccessFailure(resp.data.statusText));
				if (isShowToaster) {
					dispatch(setErrorToaster(resp.data.statusText));
				}
				setSessionExpire(resp, setSessionExpirePopUp, dispatch);
				dispatch(setUploadFileFlag(false));
			} else {
				sessionStorage.setItem("groupId", resp.data.id);
				// if (requestBody.id !== resp.data.id) {
				// 	window.history.pushState(
				// 		null,
				// 		"",
				// 		window.location.origin +
				// 			window.location.pathname +
				// 			"?groupId=" +
				// 			resp.data.id
				// 	);
				// }

				dispatch(createUpdateSegmentSuccess(resp.data));
				dispatch(setSegmentMode(mode));
				dispatch(setUploadFileFlag(false));
				if (getDetailsCallRequired) {
					const segmentDetailsRequestBody = {
						requestorID: "OFRI",
						messageVersion: process.env.REACT_APP_MESSAGE_VERSION_02,
						id: resp.data.id,
					};
					requestHeaders = {
						...requestHeaders,
						headers: {
							...requestHeaders.headers,
							...headers,
						},
					};
					dispatch(
						getSegmentDetails(
							requestHeaders,
							segmentDetailsRequestBody,
							navigate
						)
					);
				}

				// setShowOfferPopup=false;
				// dispatch(
				//   getSegmentDetails(requestHeaders, segmentDetailsRequestBody, navigate)
				// );
				// navigate("/" +window.location.pathname.split("/")[1] +SEGMENTS + DETAILS+`?groupId=${resp.data.id}&=Y`);
				//navigate(SEGMENTS + DETAILS+`?groupId=${resp.data.id}&isNew=Y`);
				if (isShowToaster) {
					dispatch(setSuccessToaster(msg ? msg : SEGMENT_SAVE));
				}
			}
		} catch (err) {
			dispatch(createUpdateSegmentFailure(err.message));
			setToaster(err, ERROR_MESSAGE, dispatch);
			//setSessionExpire(err, setSessionExpirePopUp, dispatch);
			//setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
			setSessionExpire(err, setSessionExpirePopUp, dispatch);
			setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
		}
	};
};

//upload File
export const uploadFile = (requestHeaders, requestBody) => {
	return async (dispatch) => {
		dispatch(uploadFileRequest());
		try {
			const resp = await axios.post(
				process.env.REACT_APP_URL_DOMAIN + process.env.REACT_APP_IMAGE_UPLOAD,
				requestBody,
				requestHeaders
			);
			if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
				dispatch(uploadFileSuccessFailure(resp.data));
				dispatch(setErrorToaster(resp.data.errors[0].message));
				setSessionExpire(resp, setSessionExpirePopUp, dispatch);
			} else {
				dispatch(uploadFileSuccess(resp.data));
				dispatch(setSuccessToaster(SEGMENT_FILE_UPLOAD_SUCCESS));
			}
		} catch (err) {
			dispatch(uploadFileFailure(err.message));
			setToaster(err, ERROR_MESSAGE, dispatch);
			setSessionExpire(err, setSessionExpirePopUp, dispatch);
			setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
		}
	};
};

export const uploadFileRequest = () => {
	return {
		type: UPLOAD_FILE_REQUEST,
	};
};

export const uploadFileSuccess = (apiData) => {
	return {
		type: UPLOAD_FILE_SUCCESS,
		payload: apiData,
	};
};

export const uploadFileFailure = (error) => {
	return {
		type: UPLOAD_FILE_FAILURE,
		payload: error,
	};
};

export const uploadFileSuccessFailure = (error) => {
	return {
		type: UPLOAD_FILE_SUCCESS_FAILURE,
		payload: error,
	};
};

export const getSegmentListRequest = () => {
	return {
		type: GET_SEGMENT_LIST_REQUEST,
	};
};
export const getSegmentListSuccess = (apiData) => {
	return {
		type: GET_SEGMENT_LIST_SUCCESS,
		payload: apiData,
	};
};
export const getSegmentListFailure = (error) => {
	return {
		type: GET_SEGMENT_LIST_FAILURE,
		payload: error,
	};
};
export const getSegmentListSuccessFailure = (error) => {
	return {
		type: GET_SEGMENT_LIST_SUCCESS_FAILURE,
		payload: error,
	};
};

export const getSegmentDetailsRequest = () => {
	return {
		type: GET_SEGMENT_DETAILS_REQUEST,
	};
};
export const getSegmentDetailsSuccessFailure = (error) => {
	return {
		type: GET_SEGMENT_DETAILS_SUCCESS_FAILURE,
		payload: error,
	};
};
export const getSegmentDetailsSuccess = (apiData) => {
	return {
		type: GET_SEGMENT_DETAILS_SUCCESS,
		payload: apiData,
	};
};
export const getSegmentDetailsFailure = (error) => {
	return {
		type: GET_SEGMENT_DETAILS_FAILURE,
		payload: error,
	};
};
export const searchSegmentContentRequest = () => {
	return {
		type: SEARCH_SEGMENT_CONTENT_REQUEST,
	};
};
export const searchSegmentContentSuccessFailure = (error) => {
	return {
		type: SEARCH_SEGMENT_CONTENT_SUCCESS_FAILURE,
		payload: error,
	};
};
export const searchSegmentContentSuccess = (apiData) => {
	return {
		type: SEARCH_SEGMENT_CONTENT_SUCCESS,
		payload: apiData,
	};
};
export const searchSegmentContentFailure = (error) => {
	return {
		type: SEARCH_SEGMENT_CONTENT_FAILURE,
		payload: error,
	};
};

export const createUpdateSegmentRequest = () => {
	return {
		type: CREATE_UPDATE_SEGMENT_REQUEST,
	};
};
export const createUpdateSegmentSuccessFailure = (error) => {
	return {
		type: CREATE_UPDATE_SEGMENT_SUCCESS_FAILURE,
		payload: error,
	};
};
export const createUpdateSegmentSuccess = (apiData) => {
	return {
		type: CREATE_UPDATE_SEGMENT_SUCCESS,
		payload: apiData,
	};
};
export const createUpdateSegmentFailure = (error) => {
	return {
		type: CREATE_UPDATE_SEGMENT_FAILURE,
		payload: error,
	};
};
export const resetCreateUpdateSegmentResponse = () => {
	return {
		type: RESET_CREATE_UPDATE_SEGMENT,
	};
};
export const setBlankSessionIdError = () => {
	return {
		type: SET_BLANK_SESSION_ID_ERROR,
	};
};
export const setSessionExpirePopUp = () => {
	return {
		type: SET_SESSION_TIMEOUT_POPUP,
	};
};
export const updateSegmentDetails = (newValue) => ({
	type: SET_SEGMENT_DETAILS,
	payload: newValue,
});

export const setUploadFileFlag = (newValue) => ({
	type: SET_UPLOAD_FILE,
	payload: newValue,
});
export const setSegmentMode = (mode) => {
	return {
		type: SET_SEGMENT_MODE,
		payload: mode,
	};
};
export const setSegmentEditMode = (isEdited) => {
	return {
		type: SET_SEGMENT_EDIT_STATUS,
		payload: isEdited,
	};
};
