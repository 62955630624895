import {
  DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME,
  DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME,
} from "../../constantComponent/Constants";
import { formatDate } from "../../utilsComponent/CommonUtil";

// remove error class from start date if input data is added.
export const removeErrorClass = () => {
  const inputStartDate = document.getElementById("input-start-date");
  if (inputStartDate?.value) {
    const startDate = document.getElementById("start-date");
    startDate.classList.remove("error-start-date");
  }
  return;
};
export const dateHandler = (name, value, offerDetailValues, setValues) => {
  setValues((prev) => {
    return {
      ...prev,
      [name]: value ? value : 0,
    };
  });
  if (name === "lastModifiedDate") {
    let isEndDateSmall =
      new Date(offerDetailValues?.offerEndDate) < new Date(value);
    if (isEndDateSmall) {
      setValues((prev) => {
        return {
          ...prev,
          ["lastModifiedDate"]: value,
        };
      });
    }
  }
  return;
};

export const validateDescription = (e) => {
  if (e.target.value !== "") {
    const validatedString = e.target.value.replace(
      /[*|\":<>/[\]{}`\\()';@&$]/g,
      ""
    );
    return validatedString;
  }
  return "";
};
export function convertUTCDateToLocalDate(date) {
  if (date) {
    date = new Date(date);
    var newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);
    return newDate;
  }

  return date;
}
export const getCustomStyles = () => {
  return {
    control: (provided, state) => ({
      border: "none",
      borderRadius: "4px",
      display: "flex",
      padding: "0px",
      outline: "none",
      height: "34px",
      position: "relative",
      top: "-8px",
      left: "-8px",
      margin: 0,
      // Add more custom styles as needed
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
        : state.isFocused
        ? DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME
        : "#fff",
      color: state.isSelected
        ? "#fff"
        : state.isFocused
        ? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
        : DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME,
      padding: 8,
      margin: 0,
    }),
    // Add more style properties as needed
  };
};
